import Grid from "@mui/material/Grid2";
import React from "react";
import {content} from "../constants/paragraphs"
import Typography from "@mui/material/Typography";
import GuidingPrinciplesCard from "../components/GuidingPrinciplesCard";
import {Divider, Paper} from "@mui/material";
import theme from "../theme";
import Typewriter from 'typewriter-effect';
import Box from "@mui/material/Box";

const Home = () => {
  return (
        <Grid container spacing={2}>
          <Grid size={12}>
            <Typography
                variant="h5"
                component="h2"
                color={"primary"}
                marginTop={1}
            >
              {content.missionStatement}
            </Typography>
          </Grid>
          <Grid size={12}>
            <Divider sx={{
              marginY: { sm:  "1rem", md: "2rem"},
              "&::before, &::after": {
                borderColor: theme.palette.primary.main, // Change the line color
              },
              color: theme.palette.primary.main}} >Our Mission</Divider>
          </Grid>
          <Grid size={{xs: 12, sm: 12, md: 4}}>
            <Paper elevation={3} sx={{
              minHeight: {
                xs: '14.5rem',
                sm: '14.87rem',
                md: '15.5rem',

              },
              paddingX: '1.5rem',
              boxSizing: 'border-box',
              alignContent: 'center',

            }}>
              <Typography variant="h4">
                <Typewriter
                    options={{
                      strings: content.about.votedefense.commitment_splash,
                      autoStart: true,
                      loop: false,
                      delay: '28'
                    }}
                />
              </Typography>
            </Paper>
          </Grid>
            <Grid size={{xs: 12, sm: 12, md: 8}}>
              <Paper elevation={3} sx={{
                height: "100%",
                backgroundColor: theme.palette.grey["900"],
                opacity: '70%',
                paddingY: '1rem',
                boxSizing: 'border-box',
                alignContent: 'center',
                paddingX: { xs: ".5rem", sm: ".8rem", md: "1rem" },
              }}>
                <Box>
                  <Box sx={{
                    boxSizing: 'border-box',
                    marginBottom: "1rem",
                  }}>
                    <Typography variant={"h5"} sx={{
                      opacity: '100%',
                      color: theme.palette.primary.light,
                    }}>
                      {content.about.votedefense.commitment1}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant={"body1"} sx={{
                      opacity: '100%',
                      color: theme.palette.primary.light,
                    }}>
                      {content.about.votedefense.commitment2}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant={"body1"} sx={{
                      opacity: '100%',
                      color: theme.palette.primary.light,
                    }}>
                      {content.about.votedefense.commitment3}
                    </Typography>
                  </Box>
                </Box>
              </Paper>
            </Grid>
          <Grid size={12}>
            <Divider sx={{
              marginY: { sm:  "1rem", md: "2rem"},
              "&::before, &::after": {
                borderColor: theme.palette.primary.main, // Change the line color
              },
              color: theme.palette.primary.main}} >
              Our Vision
            </Divider>
          </Grid>
          <Grid size={12}>
            <Paper elevation={3} sx={{
              backgroundColor: theme.palette.grey["900"],
              opacity: '70%',
              paddingY: '1rem',
              boxSizing: 'border-box',
              paddingX: { xs: ".5rem", sm: ".8rem", md: "1rem" },
            }}>
              <Typography variant={"h5"}  sx={{
                opacity: '100%',
                color: theme.palette.primary.light,
              }}>
                {content.about.votedefense.vision}
              </Typography>
            </Paper>
          </Grid>
          <Grid size={12}>
            <Divider sx={{
              marginY: { sm:  "1rem", md: "2rem"},
              "&::before, &::after": {
                borderColor: theme.palette.primary.main, // Change the line color
              },
              color: theme.palette.primary.main
            }}>
              Guiding Principles
            </Divider>
          </Grid>
            {content.guidingPrinciples.map((guidingPrinciple) => (
                <Grid size={{xs: 12, sm: 6, md: 4}} sx={{ display: 'flex'}}>
                  <GuidingPrinciplesCard key={guidingPrinciple.objective.toLowerCase().replace(/\s+/g, '-')} guidingPrinciple={guidingPrinciple} />
                </Grid>
            ))}
        </Grid>
    );
}

export default Home;
