import React from 'react';
import {Paper} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const MyImage = ({ image }) => {
  return (
      <Box
          component="img"
          src={image}
          sx={{
            width: '50%',
            height: 'auto',
            maxHeight: '30vh'
          }}
      />
  )
}

const MyText = ({ text }) => {
  return (
      <Box
          sx={{
            display: 'flex',           // Flexbox for centering
            justifyContent: 'center',   // Horizontally center the text
            alignItems: 'center',       // Vertically center the text
            flexGrow: 1,
            textAlign: 'center',        // Center the text itself
            padding: 2,
          }}
      >
        <Typography
            variant="body2"
            color="textSecondary"
            sx={{
              fontSize: {
                xs: '.75rem',  // For small screens
                sm: '1rem',  // For medium screens
                md: '1.5rem',  // For large screens
              },
            }}
        >
          {text}
        </Typography>
      </Box>
  )
}

const MyPaper = ({ children }) => {
  return (
      <Paper elevation={ 3 }
             sx={{
               display: 'flex',
               flexDirection: 'row',
               justifyContent: 'space-between',
               alignItems: 'stretch', // Ensure children stretch to fill the height
               padding: 2, // Optional: Add some padding
               height: '100%', // Ensure the Paper takes full height of its container
             }}>
        {children}
      </Paper>
  )
}

const ResponsiveInfoCard = ({ text, image, imageLeft }) => {
  if (imageLeft) {
    return (
        <MyPaper>
          <MyImage image={image}/>
          <MyText text={text}/>
        </MyPaper>
    )
  } else {
    return (
      <MyPaper>
        <MyText text={text}/>
        <MyImage image={image}/>
      </MyPaper>
    );
  }


};

export default ResponsiveInfoCard;