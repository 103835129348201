import React from 'react';
import Typography from "@mui/material/Typography";
import {Divider, Paper} from "@mui/material";

const GuidingPrinciplesCard = ({guidingPrinciple}) => {
  return (
        <Paper elevation={3} sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          p: 2,
        }}>
          <Typography variant="h6" component="h3" color="textPrimary">
          {guidingPrinciple.objective}
          </Typography>
          <Divider
              sx={{
                borderBottomWidth: 2,
              }}/>
          <Typography
              variant="body1" color="textSecondary"
              sx={{ flexGrow: 1 }}
          >
            {guidingPrinciple.description}
          </Typography>
        </Paper>
  );
};

export default GuidingPrinciplesCard;