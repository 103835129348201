// Import the necessary Firebase modules
import { initializeApp } from "firebase/app";

// Your Firebase config here
const firebaseConfig = {
  apiKey: "AIzaSyDu9Vh6znxecndPL6iBdxYsdwztMq44-UE",
  authDomain: "voteguard-eb4a2.firebaseapp.com",
  projectId: "voteguard-eb4a2",
  storageBucket: "voteguard-eb4a2.appspot.com",
  messagingSenderId: "169996088932",
  appId: "1:169996088932:web:d0d1d814d4bf9592551171",
  measurementId: "G-DZ0C0QB9CG",
};

// Initialize Firebase
const firebase_connection = initializeApp(firebaseConfig);

export default firebase_connection;
// Now you can use Firebase services in your React app!
