import React from "react";
import Box from "@mui/material/Box";
import about1 from "../assets/votedefense-about1.webp";
import about2 from "../assets/votedefense-about2.webp";
import about3 from "../assets/votedefense-about3.webp";
import {content} from "../constants/paragraphs"
import ResponsiveInfoCard from "../components/ResponsiveInfoCard";

const About = () => {
  return (
      <Box sx={{display:"flex", flexDirection:"column", gap: 2}}>
        <ResponsiveInfoCard image={about1} text={content.about.votedefense.about1} />
        <ResponsiveInfoCard image={about2} text={content.about.votedefense.about2} imageLeft={true} />
        <ResponsiveInfoCard image={about3} text={content.about.votedefense.about3} />
      </Box>

  );
}

export default About;
