import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import logo from "../assets/VoteDefense transpare.png";
import theme from "../theme";

function ResponsiveAppBar({ handleHomeClick, menuItems }) {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
      <AppBar position="static" sx={{
        color: theme.palette.info.main,
        backgroundColor: theme.palette.background.default,
        boxShadow: 'none',
        opacity: '95%'
      }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box
                component="img"
                src={logo}
                alt="Vote Defense logo"
                sx={{
                  maxWidth: '100%',
                  height: 'auto',
                  maxHeight: '50px',
                }}
                onClick={handleHomeClick}
            />

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, justifyContent: 'flex-end' }}>
              <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                  sx={{ paddingRight: 0 }}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{ display: { xs: 'block', md: 'none' } }}
              >
                {menuItems.map((menuObject) => (
                    <MenuItem key={menuObject.name} onClick={() => { handleCloseNavMenu(); menuObject.handleClick(); }}>
                      <Typography sx={{ textAlign: 'center' }}>{menuObject.name}</Typography>
                    </MenuItem>
                ))}
              </Menu>
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end' }}>
              {menuItems.map((menuObject) => (
                  <Button
                      key={menuObject.name}
                      onClick={() => { handleCloseNavMenu(); menuObject.handleClick(); }}
                      sx={{ my: 2, color: 'white', display: 'block' }}
                  >
                    {menuObject.name}
                  </Button>
              ))}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
  );
}

export default ResponsiveAppBar;
