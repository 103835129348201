import React, { useEffect, useRef, useState } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import firebase_connection from "./configuration";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import ResponsiveAppBar from "./components/ResponsiveAppBar";
import { Container, alpha } from "@mui/material";
import theme from "./theme";
import Footer from "./components/Footer";
import Box from "@mui/material/Box";

function App() {
  const [flagAnimation, setFlagAnimation] = useState(null);
  const navigate = useNavigate();
  const videoRef = useRef(null);

  const handleHomeClick = () => {
    navigate("/");
  };
  const handleAboutClick = () => {
    navigate("/about");
  };
  // const handleContactClick = () => {
  //   navigate("/contact");
  // };

  const menuItems = [
    { name: "Home", handleClick: handleHomeClick },
    { name: "About", handleClick: handleAboutClick },
    // { name: 'Contact', handleClick: handleContactClick }
  ];

  useEffect(() => {
    const fetchVideoURL = async () => {
      try {
        const storage = getStorage(firebase_connection);
        const backgroundVideoRef = ref(
            storage,
            "public/videos/vecteezy_united-states-of-america-realistic-waving-flag-smooth_10248697.mp4"
        );
        const url = await getDownloadURL(backgroundVideoRef);
        setFlagAnimation(url);
      } catch (error) {
        console.error("Error fetching video URL:", error);
      }
    };

    fetchVideoURL();
  }, []);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 0.5; // Set desired playback speed
    }
  }, [flagAnimation]); // Run this effect after the video URL has been set

  return (
      <div className="App">
        <video
            ref={videoRef}
            className="videoTag"
            autoPlay
            loop
            muted
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: "cover",
              zIndex: "-1",
            }}
        >
          {flagAnimation && <source src={flagAnimation} type="video/mp4" />}
        </video>

        <ResponsiveAppBar
            handleHomeClick={handleHomeClick}
            menuItems={menuItems}
        />
        <Box
            sx={{
              background: `linear-gradient(
                to bottom,
                ${alpha(theme.palette.background.default, 0.95)} 0%,
                ${alpha(theme.palette.background.default, 0.95)} 50%,
                ${alpha(theme.palette.background.default, 0)} 90%,
                ${alpha(theme.palette.background.default, 0)} 100%
              )`,
            }}
        >
          <Container
              maxWidth="lg"
              sx={{
                position: "relative",
                minHeight: "calc(100vh - 50px - 20px)",
                display: "flex",
                flexDirection: "column",
              }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <Routes>
                <Route exact path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                {/*<Route path="/about" element={<Contact/>}/>*/}
              </Routes>
            </Box>

            <Footer />
          </Container>
        </Box>
      </div>
  );
}

export default function AppWrapper() {
  return (
      <Router>
        <App />
      </Router>
  );
}
