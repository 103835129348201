import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'dark',
    // primary: {
    //   dark: "#3E2FFF",
    // }

  },
})

export default theme;