import React from 'react';
import { Box, Typography, Link as MuiLink } from '@mui/material';

function Footer() {
  return (
      <Box
          component="footer"
          sx={{
            marginTop: 'auto',
            marginBottom: '20px', // Distance from the bottom
          }}
      >
        <Typography
            variant="body2"
            align="center"
            sx={{
              marginTop: '5px',
            }}
        >
          {'Copyright © '}
          <MuiLink color="inherit" href="https://votedefense.com/">
            VoteDefense
          </MuiLink>{' '}
          {new Date().getFullYear()}.
          Waving Flag Animation Courtesy of Vecteezy.com
        </Typography>
      </Box>
  );
}

export default Footer;
