export const content = {
  missionStatement: "Harnessing the power of cutting-edge technology to ensure that every vote counts in shaping the future.",
  guidingPrinciples: [
    // developSecureVotingPlatforms: {
    //   objective: "Develop Secure Voting Platforms",
    //   description: "Create secure and user-friendly platforms that protect voter data and enhance trust in the electoral process."
    // },
    // {
    //   objective: "Expand Digital Access",
    //   description: "Provide tools that enable voters to participate from anywhere, making voting more accessible to all demographics."
    // },
    // {
    //   objective: "Enhance Voter Education",
    //   description: "Offer comprehensive resources to educate voters on their rights, the candidates, and the issues that matter."
    // },
    {
      objective: "Promote Civic Engagement",
      description: "Use technology to foster community involvement and increase voter turnout through targeted outreach and engagement campaigns."
    },
    {
      objective: "Support Transparency",
      description: "Build systems that ensure transparency in the voting process, allowing for clear tracking and verification of every vote."
    },
    // {
    //   objective: "Leverage Data Analytics",
    //   description: "Utilize data analytics to understand voter behavior and improve the effectiveness of voter outreach efforts."
    // },
    {
      objective: "Facilitate Voter Registration",
      description: "Simplify the voter registration process through intuitive digital tools that guide users step by step."
    },
    // encourageInclusivity: {
    //   objective: "Encourage Inclusivity",
    //   description: "Design platforms that are accessible to all, including those with disabilities, ensuring that every voice can be heard."
    // },
    {
      objective: "Strengthen Election Security",
      description: "Implement advanced security measures to ensure the integrity of the voting process."
    },
    // {
    //   objective: "Integrate AI for Personalization",
    //   description: "Use AI to tailor voting information and reminders to individual voters, increasing engagement and participation."
    // },
    {
      objective: "Foster Collaboration",
      description: "Partner with civic organizations and governments to expand the reach and impact of voter empowerment initiatives."
    },
    // {
    //   objective: "Advocate for Policy Change",
    //   description: "Support policies that make voting easier and more secure, advocating for reforms that benefit all voters."
    // },
    // {
    //   objective: "Drive Innovation in Voting Tech",
    //   description: "Continuously innovate with emerging technologies to keep the voting process ahead of potential challenges."
    // },
    // {
    //   objective: "Measure Impact",
    //   description: "Regularly assess the effectiveness of our tools and strategies to ensure they are making a positive impact on voter participation."
    // },
    {
      objective: "Promote Ethical Use of Technology",
      description: "Ensure that all technologies used in our platforms are deployed ethically and with the utmost respect for voter privacy and rights."
    }
  ],
  about: {
    john: {
      bio: "John Carden's extensive background in cloud technology, data solutions, and secure software development " +
          "uniquely positions him to advance the mission of voter empowerment. With a passion for making complex data " +
          "accessible and actionable, John brings a wealth of experience in deploying innovative technologies that " +
          "ensure every voice is heard and every vote counts. His commitment to using cutting-edge tools like AI, " +
          "machine learning, and secure cloud environments will help facilitate creating user-friendly platforms " +
          "that truly resonate with the average voter, making civic participation easier and more impactful than ever."
    },
    votedefense: {
      commitment1: "We are building a collection of services that will help individuals and organizations to improve voter awareness and engagement.",
      commitment2: "VoteDefense is committed to enhancing the way Americans engage with democracy through a dedicated 'voter-first' approach.",
      commitment3: "We are working for all voters to have a voice, and a seat at the table.",
      commitment_splash: "At VoteDefense, we are committed to empowering voters.",
      vision: "We envision a future where every eligible voter is informed, engaged, and confident in exercising " +
          "their right to vote. By removing barriers and providing accessible resources, we aim to make civic " +
          "participation more inclusive and impactful than ever before.",
      about1: "VoteDefense became a registered LLC in 2024. We are steadfastly committed to voter empowerment and " +
          "engagement.",
      about2: "Through the adoption of modern software data and design principles, we are building a set of tools " +
          "designed for voters.",
      about3: "By partnering with voter data organizations and non-profits, we are driving powerful outcomes for " +
          "voters all across these great United States."
    }
  }
};
